import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

import { coreService, ourWork } from '@constants/qaService';

// Assets
import headerImage from '@assets/services/qa/qa-header.jpg';

import CoreTestingIcon from '@assets/services/qa/core-testing.svg';
import DataAssuranceIcon from '@assets/services/qa/data-assurance.svg';
import DigitalTestingIcon from '@assets/services/qa/digital-testing.svg';
import SpecializedTestingIcon from '@assets/services/qa/specialized-testing.svg';
import TestConsultingIcon from '@assets/services/qa/test-consulting.svg';

const ListWrapper = styled.div`
  width: ${p => (p.width ? p.width + 'px' : 'auto')};
  margin: ${p => (p.center ? '0 auto' : 0)};
  margin-top: ${p => (p.marginTop ? p.marginTop + 'px' : 0)};
`;

const ListItem = styled.div`
  position: relative;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
`;

const ElemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center;
`;

const ItemWrapper = styled.div`
  padding: 20px;
  flex: 0 0 33.33%;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    flex: 0 0 50%;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 100%;
    p,
    div {
      text-align: left;
    }
  }
  svg {
    margin-bottom: 20px;
  }
`;

const WorkItemWrapper = styled.div`
  display: inline-flex;
  flex: 0 0 50%;
  flex-flow: column wrap;
  text-align: left;
  padding: 20px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 100%;
  }
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

const SubHeading = styled.p`
  margin-top: 20px;
  font-weight: 700;
`;

const SubContent = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const BenefitItem = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
  &::before {
    content: ' ';
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary.base};
  }
`;

const QualityAssuranceService = () => {
  const imageMap = {
    digitaltesting: <DigitalTestingIcon />,
    coretesting: <CoreTestingIcon />,
    specialisedtesting: <SpecializedTestingIcon />,
    testconsulting: <TestConsultingIcon />,
    dataassurance: <DataAssuranceIcon />,
  };
  return (
    <Layout>
      <Helmet title={'Quality Assurance'} />
      <PageHero image={headerImage} />
      <Heading title="Quality Assurance Services" noHeadingIcon={true}>
        <p>
          Quality over quantity. We all can agree over that. Well, that's what
          we mean when we offer our quality assurance services. With our QA
          services, there are several pros you're going to get for sure like
          they're aimed at reducing the cost of development, improving
          time-to-market and minimizing technical and business risks, no matter
          application/platform or complexity of your quality assurance testing
          requirements.
        </p>

        <p>
          We aim to eliminate defects at the inception stage, during the
          development phase. This avoids multiple iterations from testing to
          fixing defects and testing again. We eliminate the problems from its
          roots before it turns out to become an enormous problem.{' '}
        </p>

        <p>
          We consolidate the most advanced methodologies, technical expertise
          and industry's best practices, which is emphasised by world-class
          tools to approach the current testing hurdles.
        </p>
      </Heading>
      <Container>
        <SectionHeading>Our core services</SectionHeading>
        <ElemWrapper>
          {coreService.map((item, idx) => {
            return (
              <ItemWrapper key={idx}>
                {imageMap[item.type]}
                <SubHeading>{item.heading}</SubHeading>
                <SubContent>{item.content}</SubContent>
                <ListWrapper>
                  {item.list.map((listElem, id) => {
                    return <ListItem key={id}>{listElem}</ListItem>;
                  })}
                </ListWrapper>
              </ItemWrapper>
            );
          })}
        </ElemWrapper>
      </Container>
      <Container>
        <SectionHeading>How do we work</SectionHeading>
        <ElemWrapper>
          {ourWork.map((item, id) => {
            return (
              <WorkItemWrapper key={id}>
                <Title>{item.heading}</Title>
                <ListWrapper>
                  {item.content.map((ele, idx) => {
                    return <ListItem key={idx}>{ele}</ListItem>;
                  })}
                </ListWrapper>
              </WorkItemWrapper>
            );
          })}
        </ElemWrapper>
      </Container>
      <Container>
        <SectionHeading>
          Assured benefits by selecting our quality assurance services
        </SectionHeading>
        <ListWrapper width={350} marginTop={50} center={true}>
          <BenefitItem>Certified testing specialists</BenefitItem>
          <BenefitItem>Domain-specific testing consultants</BenefitItem>
          <BenefitItem>Early defect detection and resolution</BenefitItem>
          <BenefitItem>The full life-cycle testing approach</BenefitItem>
          <BenefitItem>Quantifiable results</BenefitItem>
          <BenefitItem>Timely and high-quality releases</BenefitItem>
          <BenefitItem>Accelerated product releases</BenefitItem>
        </ListWrapper>
      </Container>
    </Layout>
  );
};

export default QualityAssuranceService;
