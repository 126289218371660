export const coreService = [
  {
    type: 'digitaltesting',
    heading: 'Digital Testing',
    content:
      'By this, our experts ensure you seamless intercommunications within the digital ecosystem over social, ' +
      'mobile, analytics, and cloud (SMAC). Our team conducts extensive testing across web portals, analytics, ' +
      'content for the companies by leveraging our already well-established tools, accelerators, IPs, and frameworks.',
    list: [
      'Omni-channel Testing',
      'Multi-channel & IoT Testing',
      'Hybrid Cloud App Testing',
      'Deployment & Infra Testing',
      'Business Process Validation',
      'A/B Testing',
      'Digital Asset Management Testing',
      'Media Testing',
      'DRM Testing',
    ],
  },
  {
    type: 'coretesting',
    heading: 'Core Testing',
    content:
      'With an undeniable quality assurance method, we have been accommodating global enterprises increase ' +
      'predictability, increase attainment, significantly lessen the total cost of testing and achieve higher ROI.',
    list: [
      'Functional Testing',
      'Test Automation',
      'Compatibility Testing',
      'Performance Engineering',
      'Security Testing',
    ],
  },
  {
    type: 'specialisedtesting',
    heading: 'Specialized Testing',
    content:
      'We provide services for testing in specialized fields like blockchain, robotic process automation, etc. ' +
      'These are essential as they provide important solutions for ongoing testing procedures combined with established methods, ' +
      'expertise and best practices, backed by best-in-class tooling.',
    list: [
      'Accessibility Testing',
      'CRM Testing',
      'Robotic Process Automation',
      'Blockchain Testing',
      'UX Testing',
      'Localization Testing',
      'API Testing',
      'Big Data Testing',
      'Cloud Migration Testing',
      'DevOps Quality Assurance',
      'Cloud Ops Assurance',
      'Agile DevOps',
    ],
  },
  {
    type: 'testconsulting',
    heading: 'Test Consulting',
    content:
      'With our different go-to assessment tools, we provide top-notch guidance and consulting for your ongoing QA processes. ' +
      "CodeMyMobile's QA services enable global enterprises to achieve a higher level of test maturity in a fast way.",
    list: [
      'Test Management Assessment',
      'Test Automation Assessment',
      'Test Audit',
      'Test Environment Assessment',
      'Test Process Assessment',
      'Tools and Methodologies Assessment',
      'Security Test Assessment',
    ],
  },
  {
    type: 'dataassurance',
    heading: 'Data Assurance',
    content:
      'In modern life, data is the most important entity in any industry. And with an increasing amount of data everywhere, ' +
      "it becomes a heck of a job managing it. CodeMyMobile's data assurance testing services enable enterprises to re-engineer " +
      'their critical infrastructure.',
    list: [
      'Data Consolidation Testing',
      'DB/BI Reports',
      'ETL Testing',
      'Test Data Management',
    ],
  },
];

export const ourWork = [
  {
    heading: 'Create a custom strategy',
    content: [
      'Review & QA estimates',
      'Test activity roles & responsibilities',
      'Test strategy development',
    ],
  },
  {
    heading: 'Evaluate testing results and provide reports',
    content: [
      'Define Project goals and priorities',
      'Implement the best practices of the QA industry',
      'Delivery of a high-quality product',
    ],
  },
  {
    heading: 'Set up the environment for an efficient testing process',
    content: [
      'Requirements development and/or review',
      'Test condition development',
      'Test cycle development',
    ],
  },
  {
    heading: 'Deploy & Run',
    content: [
      'Operational readiness',
      'Post-deployment Validation',
      'Performance Validation',
      'Continuous improvement & innovation',
      'Knowledge repository and proficiency management',
    ],
  },
];
